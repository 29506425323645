import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';
import { bannerData } from '../../data/index';
import React from 'react';
import Section, {
    BannerContent, BannerContentWrapper, BannerText, Figure,
    HighlightedText
} from './banner.style';
import Button from '../../common/components/Button';
import { bannerImageLandingPage } from '../../RoutesContainer/Imagelinks';

const Banner = () => {
    const { title, text, button, news, newsLabel } = bannerData;

    const navigateToTest = () => {
        window.open( 'https://jnmfreecounselling.youcanbook.me/', '_blank' );
    };


    return (
        <Section id="home">
            <Container width="1400px">
                <BannerContentWrapper>
                    <BannerContent>
                        <Heading
                            className="animate__animated animate__fadeInUp banner-title"
                            content={ title }
                        />
                        <BannerText>
                            <div className="animate__animated animate__fadeInUp">
                                { text }
                            </div>
                            <Box className="buttonBox">
                                <Button onClick={ navigateToTest } className="contactBtn" title="Book a Free Consultation!" />
                            </Box>
                        </BannerText>
                    </BannerContent>
                    <Figure className="animate__animated animate__fadeInUp animate__fast">
                        <Image src={ bannerImageLandingPage } alt="dashboard" preview={ false } />
                    </Figure>

                </BannerContentWrapper>
            </Container>
        </Section>
    );
};

export default Banner;
