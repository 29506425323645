import React from 'react';
import Container from '../../common/components/UI/Container';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';

import { ContactUsWrapper } from './contactUs.style';

const ContactUs = () => {

  const navigateToTest = () => {
    window.open( 'https://jnmfreecounselling.youcanbook.me/', '_blank' );
  };

  return (
    <ContactUsWrapper id="contact_section">
      <Container>
        <Box className="contactInner">
          <Box className="leftContent">
            <Heading as="h3" content="Launch Your Success Story with JenNext:" />
            <Heading
              as="h3"
              className="color2"
              content="30-Minute Expert Session: Experience a transformative mentorship session with our seasoned experts. Get tailored advice, strategic insights, and a roadmap to your dream university. "
            />
          </Box>
          <Box className="buttonBox">
            <Button onClick={ navigateToTest } className="contactBtn" title="Book a Free Consultation!"/>
          </Box>
        </Box>
      </Container>
    </ContactUsWrapper>
  );
};

export default ContactUs;
